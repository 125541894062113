/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Layout, StaticSEO } from '@core';
import { FinnoScorePage } from '@composite';

export default () => (
  <Layout>
    <StaticSEO pageId='finnoscore-insurance' />
    <FinnoScorePage />
  </Layout>
);
